<template>
  <!-- 列表 -->
  <van-list
    v-model:loading="loading"
    v-model:error="error"
    :finished="finished"
    :finished-text="list.length == 0 ? '暂无数据' : '没有更多了'"
    error-text="请求失败，点击重新加载"
    @load="onNext"
  >
    <div
      v-for="item of list"
      :key="item.id"
      class="content-item"
      @click="$emit('click-item', item)"
    >
      <div class="text" v-html="item.content"></div>
      <van-button
        v-if="!setting"
        type="primary"
        size="small"
        @click.stop="onSend(item)"
      >
        分享
      </van-button>
      <van-button
        v-else
        type="danger"
        size="small"
        @click.stop="onDelete(item)"
      >
        删除
      </van-button>
    </div>
  </van-list>
</template>

<script>
import { toRefs, watch } from 'vue'
import { List, Button, Dialog, Toast, Notify } from 'vant'
import { pagination } from '../../../utils/pagination'
import { sendChatMessage } from '../../../utils/nwechat'
import { addFlollow } from '../../../service/customer.service'
import { getSpeechcraft, delSpeechcraft } from '../../../service/tool.service'

export default {
  components: {
    [List.name]: List,
    [Button.name]: Button
  },
  props: {
    toast: {
      type: Boolean,
      default: false
    },
    groupId: {
      type: [Number, String]
    },
    customerId: {
      type: [Number, String]
    },
    params: {
      type: Object,
      default: () => ({})
    },
    setting: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { params, toast } = toRefs(props)

    const {
      list,
      loading,
      finished,
      refreshing,
      error,
      onRefresh,
      onNext
    } = pagination(getSpeechcraft, { params, pageMode: true })

    watch(params, async () => {
      const _toast = toast.value
      list.value = []
      loading.value = true
      if (_toast) {
        Toast.loading({ message: '加载中...', forbidClick: true })
      }
      if (await onRefresh()) {
        if (_toast) Toast.clear()
        loading.value = false
      }
    })

    return {
      list,
      loading,
      finished,
      refreshing,
      error,
      onRefresh,
      onNext
    }
  },
  methods: {
    // 发送内容
    async onSend(params) {
      let { id, classId, content } = params
      console.log('test', params)
      try {
        await sendChatMessage(content)
        addFlollow({
          external_userid: this.customerId,
          chat_id: this.groupId,
          content_id: id,
          followup_type_id: classId
        })
      } catch (e) {}
    },
    // 删除该话术
    onDelete(item) {
      const beforeClose = async (action, done) => {
        if (action !== 'confirm') return true

        try {
          await delSpeechcraft(item.id)
          const idx = this.list.indexOf(item)
          this.list.splice(idx, 1)
          console.log('delete-item', done)
          // done && done()
          Dialog.close()
        } catch (e) {
          Dialog.close()
          Notify({ type: 'danger', message: '删除失败！' })
          return false
        }

        return true
      }

      Dialog.confirm({ message: '是否删除当前话术？', beforeClose })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/style/theme.less';

.content-item {
  display: flex;
  position: relative;
  padding: 12px 0;
  color: @gray-6;
  white-space: pre-line;
  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
    &:after {
      border-bottom: none;
    }
  }
  &:after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    pointer-events: none;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid #ebedf0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
  > .text {
    flex: 1;
  }
}
</style>
